import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import {
  Divider,
  Grid,
  Header,
  Tab,
  Menu,
  Label,
} from 'semantic-ui-react';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import IntrastatMainList from '../components/IntrastatMainList/index';
import AddIntrastatModal from '../New'
import Button from 'components/Button';
import LinkedButton from 'components/core/LinkedButton/LinkedButton';
import { Absolute, IntrastatDropdown, Traduction } from './style';

const Index = ({
  intl,
  dispatch,
  match,
  user,
  companySelected,
  intrastats,
  intrastatsLoading,
}) => {
  document.title = `MyASD Intrastat dashboard`;
  const companyId = companySelected || match.params.companyId || window.location.pathname.split('/')[3] 
  const userAdminOrOp = user.operator || user.admin;

  const [intrastatModal, setIntrastatModal]: any = useState(false);
  const [data, setData]: any = useState(null);
  const [currentData, setCurrentMonth]: any = useState([]);
  const [monthsList, setMonthList]: any = useState([])
  const [isoList, setIsoList]: any = useState([])
  const [sessionPI, setSession]: any = useState({period: '', iso: ''})

  const lang = localStorage.getItem('language')
 
  useEffect(() => {
    companyId &&
    dispatch(
        actions.intrastat.getAllIntrastats({
          params: { companyId: companyId },
        })
      );
      dispatch(actions.socket.subscribeIntrastatsALL(companyId));
      
      return () => {
        dispatch(
          actions.intrastat.reset([
            'getAllIntrastats',
            'error',
          ])
        );
        dispatch(actions.socket.unsubscribeIntrastatsALL(companyId));
      };

  }, [dispatch, companyId]);

  useEffect(() => {
    document.title = `MyASD Intrastat monitoring`;
    if(intrastats){
      // current period tabpane
      const current = new Date()
      const currentIntrastats = intrastats.filter(
        (e) => Number(new Date(e.period).getMonth()) === Number(current.getMonth()+1) && e.state !== 'cancelled'
      )
      // filing period less one month 
      currentIntrastats && setCurrentMonth(currentIntrastats)
  
      // create filter lists
      const activePeriods: any = [], iso: any = [];
      for(const item of intrastats){
        const found = activePeriods.some((value) => value === item.periodString);
        const isoFound = iso.some((value) => value === item.filingCountry);
        if (!found) {
          activePeriods.push(item.periodString);
        }
        if(!isoFound){
          iso.push(item.filingCountry)
        }
      }
      console.log(lang)
      setMonthList(activePeriods.map((month, index) => {
        return {
          key: index,
          value: month,
          text: `${month.split('-')[0]} - ${new Date(month.split('-')[1]).toLocaleDateString(lang, {
            month: 'long',
          })}`
        }
      }))
      setIsoList(iso.map((el, index) => {
        return {
          key: index,
          value: el,
          text: el,
        }}))

      // init filters with stored values
      const session = JSON.parse(sessionStorage.getItem(`intra_${companyId}`))
      session && setSession(prevState => ({
        ...prevState,
        iso: session.iso,
        period: session.period
      })) 
    }

  }, [intrastats, companyId, lang]);


  useEffect(() => {
    // period and country filters
    const result = sessionPI && intrastats && intrastats.filter(
      (value) => 
        (sessionPI.period !== '' && sessionPI.iso !== '') ?
          value.periodString === sessionPI.period && value.filingCountry === sessionPI.iso 
        :
        (sessionPI.period === '' && sessionPI.iso) ?
          value.filingCountry === sessionPI.iso 
        :
        (sessionPI.iso === '' && sessionPI.period) ?
          value.periodString === sessionPI.period 
        :
        (sessionPI.period === '' && sessionPI.iso === '') ?
          value : null
      );

      if(result){
        sessionStorage.setItem(`intra_${companyId}`, JSON.stringify(sessionPI))
        setData(result)
      }
  }, [intrastats, sessionPI, companyId])

 
  const getCurrentMonth = (lower?) => {
    const date = new Date()
    const current = new Date(`${date.getMonth()+1}/1/${date.getFullYear()}`)
    const resetM = date.getMonth()
    current.setMonth(resetM)
    const month = current.toLocaleDateString(lang, {
      month: 'long',
    })
    current.setMonth(resetM+1)
    const nexMonth = current.toLocaleDateString(lang, {
      month: 'long',
    })
    return (lower && month.toLocaleLowerCase()) || `${month} ⇒ ${nexMonth}`
  }

  return (
    <Wrapper display='flex' justifyContent='center'>
    {intrastatModal && (
      <AddIntrastatModal 
        intl={intl}
        open={intrastatModal}
        handleClose={() => setIntrastatModal(false)}
        companyId={companyId}
      />
    )}
    {((intrastatsLoading || data === null) && <Loader />) || 

    (data !== null && intrastats && (
      <Wrapper height='100%' width='80%' overflow='auto'>
        <Divider hidden />
        <Wrapper display='flex'>
          {userAdminOrOp && (
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content={intl.formatMessage({id: `button.back` })}
              path={`/intrastat`}
            />
          )}       
        </Wrapper>
        <Wrapper padding='0 2rem' textAlign='center'>
        <Header size='huge' textAlign='center'>EMEBI - INTRASTAT
        {!userAdminOrOp && (<Header.Subheader>{intl.formatMessage({id: `intrastat.table.Dashboard`})} -<Traduction
            onClick={() => {
              if(localStorage.getItem('language') === 'fr'){
                localStorage.setItem('language', 'en')           
                window.location.reload();
              }else{
                localStorage.setItem('language', 'fr')
                window.location.reload();
                
              }
          }}>{(localStorage.getItem('language') && 
          localStorage.getItem('language') === 'fr') ? 'En': 'Fr'}</Traduction></Header.Subheader>)}
        </Header>
        </Wrapper>
        <Divider hidden />
        <Wrapper display='flex' justifyContent='flex-end !important'>
          <Button
            labelPosition='right'
            position='left'
            icon='plus circle'
            type='button'
            content={intl.formatMessage({id: `intrastat.newShort` })}
            onClick={() => setIntrastatModal(true)}
          />
        </Wrapper>
        <Wrapper height='100%' overflow='auto' position='relative' minHeight='300px'>
        <Tab panes={[
            {
              menuItem: (
                <Menu.Item key={0} className='intrastat'>
                  INTRASTAT
                  <Label>{data && data.length}</Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Grid>
                    <Grid.Column>           
                      <IntrastatMainList
                        intl={intl}
                        key={0}
                        data={data}
                        companyId={companyId}
                      />                                                               
                    </Grid.Column>                     
                  </Grid>
                </Tab.Pane>
              ),
            },
            {
              menuItem: (
                <Menu.Item key={1} className='intrastat'>
                  {getCurrentMonth()}
                  <Label>{currentData && currentData.length}</Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Grid>
                    <Grid.Column>
                      <Header textAlign='center' size='small'>
                        INTRAT-STATS for {getCurrentMonth(true)} 
                      </Header>                   
                      <IntrastatMainList
                        intl={intl}
                        key={0}
                        data={currentData}
                        companyId={companyId}
                      />                                                                 
                    </Grid.Column>                     
                  </Grid>
                </Tab.Pane>
              ),
            }
        ]}/>
      <Absolute>
        <IntrastatDropdown
          clearable
          search
          selection
          placeholder={intl.formatMessage({id: `intrastat.monit.pFilingPeriod` })}
          options={monthsList}
          defaultValue={sessionPI.period || null}
          onChange={(e, {value}) => {
            e && setSession(prevState => ({
              ...prevState,
              period: value
            }))
          }}
        />
        <IntrastatDropdown
          clearable
          search
          selection
          placeholder={intl.formatMessage({id: `intrastat.monit.pCountry` })}
          options={isoList}
          defaultValue={sessionPI.iso || null}
          onChange={(e, {value}) => {
            e && setSession(prevState => ({
              ...prevState,
              iso: value
            }))
          }}
        />
      </Absolute>
      <Wrapper>
      <Divider hidden />
      </Wrapper>
      </Wrapper>
  </Wrapper>
  ))}
  </Wrapper> 
  )
}
const mapStateToProps = (state) => {
  return {
    intrastats: selectors.intrastat.allIntrastatsSelector(state),
    intrastatsLoading: selectors.intrastat.loadingSelector(state),
    companySelected: selectors.auth.companySelected(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
