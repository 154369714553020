import React, { useState } from 'react';
import { Modal, Header } from 'semantic-ui-react';
import { ButtonStyled, ButtonWrapper } from './styles';

const Index = ({
  intl,
  trgrColor,
  trgrContent,
  headerContent,
  modalContent,
  onClick,
  trgrIcon,
  headerIcon,
  margin,
  position,
  maxHeight,
  labelPosition,
  disabled,
  noSubmit,
  size,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleConfirmClick = () => {
    onClick();
    setIsOpen(false);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeOnEscape={true}
      size='tiny'
      trigger={
        <ButtonWrapper
          margin={margin}
          position={position}
          maxHeight={maxHeight}
        >
          <ButtonStyled
            size={size}
            disabled={disabled}
            content={trgrContent}
            icon={trgrIcon}
            {...(noSubmit ? { type: 'button' } : { type: 'submit' })}
            labelPosition={labelPosition}
            margin='5px'
            btncolor={trgrColor}
            onClick={handleOpen}
          />
        </ButtonWrapper>
      }
      closeIcon
    >
      <Header icon={headerIcon} content={headerContent} />
      <Modal.Content>
        <span>{modalContent}</span>
      </Modal.Content>
      <Modal.Actions>
        <ButtonStyled
          content={intl.formatMessage({id: `intrastat.deletion.yes`})}
          icon='checkmark'
          type='submit'
          btncolor='blue'
          onClick={handleConfirmClick}
        />
        <ButtonStyled
          content={intl.formatMessage({id: `intrastat.deletion.no`})}
          icon='remove'
          type='submit'
          btncolor='red'
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default Index;
