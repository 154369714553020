import * as Yup from 'yup';

import config from 'config';

const customsDocuments = Yup.mixed()
  .test('fileType', 'Unsupported file format', (value) => {
    if (value) {
      const splitedName = value.name.split('.');
      return config.file.customsDocuments.fileType.includes(
        splitedName[splitedName.length - 1].toLowerCase()
      );
    }
  })
  .test('fileSize', 'File is too large', (value) => {
    return value && value.size < config.file.customsDocuments.fileSize;
  })
  .required('Required');

  export const arrayOfCustomsDocuments = Yup.object().shape({
    documents: Yup.array().of(
      Yup.object()
        .shape({
          files: Yup.array().of(
            Yup.object().shape({
              path: Yup.string().required('Required'),
              name: Yup.string().required('Required')
            })
          ),
          id: Yup.number().required('Required'),
      }).nullable(true)
    ),
    split: Yup.boolean().nullable(true),
    type: Yup.number()
})

export const arrayOfDroppedDocuments = Yup.object().shape({
  documents: Yup.array()
    .of(
      Yup.object()
        .shape({
          file: customsDocuments,
          id: Yup.number().min(1).required('Required'),
          reference: Yup.string().nullable(true)
        })
        .nullable(true)
    )
    .min(1)
    .required('Required'),
    type: Yup.number().required('Required'),
});

export const chunks = Yup.object().shape({
  start: Yup.number().min(1, 'Value cannot be inferior to 1').required(),
  end: Yup.number().required(),
  newName: Yup.string().required(),
  category: Yup.number().required(),
  tag: Yup.string().required(),
})

export const intrastat = Yup.object().shape({
  declarationType: Yup.string().required('Required'),
  month: Yup.string().required('Required'),
  year: Yup.string().required('Required'),
  filingCountry: Yup.string(),
  flow: Yup.string().required('Required'),
  clientReference: Yup.string().nullable(true),
  regularization: Yup.boolean().nullable(true),
  type: Yup.number().nullable(true),
});

export const updateIntrastat = Yup.object().shape({
  period: Yup.string().required('Required'),
  filingCountry: Yup.string().required('Required'),
  declarationType: Yup.object().shape({
    id: Yup.number().required(), 
    name: Yup.string().required('Required')
  }), 
  flow: Yup.string().required('Required'),
  clientReference: Yup.string(),
});

export const updateNoneIntrastat = Yup.object().shape({
  period: Yup.string().required('Required'),
  filingCountry: Yup.string().required('Required'),
  clientReference: Yup.string(),
});


export const settingsSchema =  Yup.object().shape({
  settings: Yup.array().of(
    Yup.object().shape({
      Country: Yup.string().required(),
      flow: Yup.string().required(),
      declarationType: Yup.string().required(),
    })
    )
})

export const monitoringFiltersSchema = Yup.object().shape({
  id: Yup.string().nullable(true),
  companyId: Yup.number().nullable(true),
  declarationType: Yup.string().nullable(true),
  flow: Yup.string().nullable(true),
  intranetReference: Yup.string().nullable(true),
  filingPeriod: Yup.string().nullable(true),
  showAll: Yup.boolean().nullable(true),
});


