import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { configureStore } from 'store';
import config from './config';
import i18n from './i18n';
import services from './services';
import App from './views';

import 'semantic-ui-css/semantic.min.css';
import 'theme/styles/main.css';
require('dotenv').config();

const servicesContainer = services(config);
const { store, persistor } = configureStore({}, servicesContainer);

const lang = localStorage.getItem('language') || 'en';

localStorage.setItem('language', lang);

const Root = () => {
  return (
    <Provider key={1} store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale={lang} messages={i18n.getMessages(lang)}>
          <App />
        </IntlProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
