import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Grid,
  Header,
  Modal,
  Message,
  Form,
  Divider,
  Icon,
} from 'semantic-ui-react';
import { Input } from 'components/Form';
import Button from 'components/Button';
import IntrastatIframe from './IntrastatIframe';
import { Delete, ListItems, Listing, Span, SplitWrapper, SplitButton, Strong } from './styles';
import { Field, Formik } from 'formik';
import { chunks } from 'interfaces/formSchema/intrastatValidators';
import Dropdown from 'components/Dropdown';


const ModalSplitSingleFile = ({
  intl,
  companyId,
  intrastatId,
  IntrastatFlow,
  data,
  chuncks,
  chuncksLoading,
  chuncksSuccess,
  sessionFiles,
  sessionFilesLoading,
  splitLoading,
  categoriesDocuments,
  split,
  handleClose,
  dispatch
}: ModalSplitSingleProps) => {
  const lang = localStorage.getItem('language')
  const [categoriesDocumentsList, setCategoriesDocumentsList]: any = useState([]);
  const [storage, setStorage]: any = useState(null)
  const [docCategory, setDocCategory]: any = useState(null)
  const [close, setIsOpen]: any = useState(null)

  const formikRef = useRef()

  const tInfo = intl.formatMessage({id: `intrastat.split.info`})
  const tFrom = intl.formatMessage({id: `intrastat.split.from`})
  const tTo = intl.formatMessage({id: `intrastat.split.to`})
  const tSendTo = intl.formatMessage({id: `intrastat.split.sendTo`})

  const session = data.session
  let storageTab = useMemo(
    () => localStorage.getItem(`${data.documentId}`) !== null ? JSON.parse(localStorage.getItem(`${data.documentId}`) || '') : []
    , [data.documentId])

  useEffect(() => {
    dispatch(actions.intrastat.getDocsFromCat({
      query: {
        type: IntrastatFlow === 'expedition' ? 0 : 1
      }
    }));
    return () => {
      dispatch(actions.intrastat.reset(['docsFromCat']));
    };
  }, [dispatch, IntrastatFlow]);

  useEffect(() => {
    if(chuncks && chuncks.length>0){
      setIsOpen(false)
    }

    storageTab && console.log(storageTab)
    if(!storage){
      setStorage(storageTab)
    }
  }, [
    sessionFiles, 
    chuncks, 
    storageTab, 
    chuncksLoading, 
    storage
  ])

  useEffect(() => {
    if(categoriesDocuments && lang){
      let tab = []
      categoriesDocuments.forEach((category, index) => {
        if(data.docCategory !== category.id){
          if(lang === 'fr'){
            tab.push({
              key: index,
              value: category.id,
              text: category.fr,
            });
          }else{
            tab.push({
              key: index,
              value: category.id,
              text: category.name,
            });
          }
         
        }else{
          if(lang === 'fr'){
            setDocCategory(category.fr)
          }else{
            setDocCategory(category.name)
          }
        }
      })
      setCategoriesDocumentsList(tab);
    }
  }, [
    categoriesDocuments, 
    data.docCategory,
    lang
  ]);


  const getStorage = () => {
    return localStorage.getItem(`${data.documentId}`) !== null ?
    JSON.parse(localStorage.getItem(`${data.documentId}`) || '') : []
  }

  const splitDocument = (sessionFiles, session) => {   
    dispatch(actions.intrastat.splitDocument({
      params: {
        companyId: companyId,
        intrastatId: intrastatId,
        documentId: data.documentId,
      },
      data:{
        files: sessionFiles,
        sessionNumber: session,
        slices: getStorage(), // all
        documentName: data.docName,
        docCategory: data.docCategory,
      }
    }))
    return () => {
      dispatch(actions.intrastat.reset(['split', 'error']))
    }  
  }



  const saveLocalStorage = (values: {category, start, end, tag, newName}, sessionFiles) => {
      storageTab.push({start: values.start, end: values.end, category: values.category, selectedTag: values.tag, newName: values.newName})
      localStorage.setItem(`${data.documentId}`, JSON.stringify(storageTab))
      if(sessionFiles && data){
        dispatch(actions.intrastat.renameSendChunk({
          params: {
            companyId: companyId,
            intrastatId: intrastatId,
            documentId: data.documentId,
          },
          data:{
            sessionNumber: session,
            slices: storageTab, // last
            documentName: data.docName,
            periodString: data.periodString,
            docCategory: values.category,
          }
        }))

        const ref: any = formikRef.current
        ref.resetForm();
        setStorage(null)

        return () => {
          dispatch(actions.intrastat.reset(['renameSendChunk', 'error']))
        }  
      }
  }

  const deleteLocalStorage = (index) => {
    storageTab.splice(index, 1)
    localStorage.setItem(
      `${data.documentId}`, JSON.stringify(storageTab)
      )
    setStorage(null)
  }

  return (
    <Modal style={{'height': '90%', 'position': 'relative'}} open={close ? close : data.open} onClose={handleClose} loading={sessionFilesLoading} size='fullscreen' closeIcon>
      <Header icon='plus circle' content={intl.formatMessage({
        id: `intrastat.split.header`}, 
        {
          id: intrastatId && intrastatId.split('-')[0],
          IntrastatFlow: IntrastatFlow  
        })} />
      <Modal.Content>
      <Message info>{tInfo}</Message>
      <Grid style={SplitWrapper} columns={2}>   
      <Grid.Column width={5}> 
      <Listing>
      {
        storageTab !== null && 
        storageTab.length>0 && 
        storageTab.map((e, index) => (
            <ListItems key={index}>{tFrom} <Span>{e.start}</Span> {tTo} <Span>{e.end}</Span> {tSendTo} <Strong>{e.selectedTag}</Strong>
              <Delete onClick={() => { 
                deleteLocalStorage(index) 
              }}>Delete</Delete>
            </ListItems>
        ))
        }
      <Divider hidden />
      <Formik
        ref={formikRef}
        initialValues={{
          start: '',
          end: '',
          newName: '',
          category: '',
          tag: '',
        }}
        enableReinitialize={true}
        onSubmit={saveLocalStorage}
        validationSchema={chunks}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
          <Grid>
          <Grid.Row columns={2}>
          <Grid.Column>
          <Field
              label={intl.formatMessage({
                id: `intrastat.split.start`
              })}
              name='start'
              placeholder={intl.formatMessage({
                id: `intrastat.split.pnumber`
              })}
              component={Input}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
          </Grid.Column>
          <Grid.Column>
          <Field
              label={intl.formatMessage({
                id: `intrastat.split.end`
              })}
              name='end'
              placeholder={intl.formatMessage({
                id: `intrastat.split.pnumber`
              })}
              component={Input}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
          </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
          <Grid.Column>
          <Field
              label={intl.formatMessage({
                id: `intrastat.split.name`
              })}
              name='newName'
              placeholder={intl.formatMessage({
                id: `intrastat.split.pname`
              })}
              component={Input}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
          </Grid.Column>
          <Grid.Column>
          <Field
              label={intl.formatMessage({
                id: `intrastat.split.docCategory`}, 
                {
                  type: docCategory
                })}
              placeholder={intl.formatMessage({
                id: `intrastat.split.pdocCategory`
              })}
              name='category'
              component={Dropdown}
              options={categoriesDocumentsList}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
          </Grid.Column>
          </Grid.Row>
          </Grid>            
            <Divider hidden />
            <Field
              label={intl.formatMessage({
                id: `intrastat.split.lDeclaCat`
              })}
              name='tag'
              placeholder={intl.formatMessage({
                id: `intrastat.split.pDeclaCat`
              })}
              component={Dropdown}
              options={[
                {key: 1, value: 'deb-intr', text: 'DEB Intro'},
                {key: 2, value: 'deb-expe', text: 'DEB Expe'},
                {key: 3, value: 'des-intr', text: 'DES Intro'},
                {key: 4, value: 'des-expe', text: 'DES Expe'},
              ]}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
            <Divider hidden />
            <Button
             disabled={chuncksLoading}
             {...(chuncksLoading && {
               icon: <Icon loading name='spinner' />,
             })}
              type='submit'
              content={intl.formatMessage({
                id: `button.submit`
              })}
              color='green'
            />
          </Form>
          )}
          </Formik>
        </Listing>
      </Grid.Column>
      <Grid.Column width={11}>
          <IntrastatIframe 
            companyId={companyId} 
            sessionId={data.session} 
            IntrastatId={data.intrastatId} 
            documentId={data.documentId}
          />
      </Grid.Column>
      </Grid>
      <Grid.Row style={SplitButton}>
      <Button
        disabled={splitLoading}
        {...(splitLoading && {
          icon: <Icon loading name='spinner' />,
        })}
        type='submit'
        content={intl.formatMessage({
          id: `intrastat.split.btn`
        })}
        color='blue'
        onClick={() => (
          splitDocument(sessionFiles, data.session)
        )
        }
      />
      </Grid.Row>
     
      
    </Modal.Content>
  </Modal>
  );
};

interface ModalSplitSingleProps {
  intl: any;
  companyId: number,
  intrastatId: string,
  IntrastatFlow: string,
  data: {
    open: boolean;
    documentId: number;
    session: string;
    split: boolean;
    tag: string;
    docCategory: number;
    docName: string;
    flowCat: number;
    periodString: string
    companyName: string;
    intrastatId: string;
  },
  handleClose: () => void;
  splitDocument: (sessionFiles, session) => void;
  dispatch: (value: any) => void;
  sessionFiles,
  categoriesDocuments,
  sessionFilesLoading,
  chuncksLoading,
  chuncksSuccess,
  chuncks,
  split,
  splitLoading,
}

const mapStateToProps = (state) => {
  return {
    sessionFiles: selectors.intrastat.getFilesFullListSelector(state),
    sessionFilesLoading: selectors.intrastat.getFilesFullListLoadingSelector(state),
    chuncks: selectors.intrastat.renameSendChuncksSelector(state),
    chuncksLoading: selectors.intrastat.renameSendChuncksLoadingSelector(state),
    chuncksSuccess: selectors.intrastat.renameSendChuncksSuccessSelector(state),
    split: selectors.intrastat.splitDocumentSelector(state),
    splitLoading: selectors.intrastat.splitDocumentLoadingSelector(state),
    categoriesDocuments: selectors.intrastat.getDocsFromCatSelector(state),
    categoriesLoading: selectors.intrastat.getDocsFromCatLoadingSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(ModalSplitSingleFile);
