import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import {
  Divider,
  Grid,
  Header,
  Tab,
  Menu,
  Label
} from 'semantic-ui-react';

import { paths as pathToIntrastat } from 'routes/intrastat';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import Button from 'components/Button';
import LinkedButton from 'components/core/LinkedButton/LinkedButton';
import IntrastatMainList from '../../components/IntrastatMainList/index';
import AddIntrastatModal from '../../New'
import IntrastatSettingsModal from 'views/Intrastat/components/IntrastatSettingsModal';


const Index = ({
  intl,
  dispatch,
  match,
  user,
  intrastats,
  intrastatsLoading,
  createIntrastatLoading,
}) => {
  document.title = `MyASD Intrastat dashboard`;
  const userAdminOrOp = user.operator || user.admin;
  const companyId = match.params.companyId;
  const iso = match.params.iso;
  const lang = localStorage.getItem('language')

  const [intrastatModal, setIntrastatModal] = useState(false);
  const [data, setData] = useState(null);
  const [currentData, setCurrentMonth] = useState([]);
  const [settings, setSettingsModal] = useState(false);

  useEffect(() => {
    dispatch(
      actions.intrastat.getAllIntrastats({
        params: { companyId: companyId },
        query: {
          operator: userAdminOrOp,
          country_code: iso
        }
      })
    );
    dispatch(actions.socket.subscribeIntrastats(companyId, userAdminOrOp, iso));
    
    return () => {
      dispatch(
        actions.intrastat.reset([
          'getAllIntrastats',
          'error',
        ])
      );
      dispatch(actions.socket.unsubscribeIntrastats(companyId, userAdminOrOp, iso));
  };

}, [dispatch, iso, userAdminOrOp, companyId]);

  useEffect(() => {
    if(intrastats){
      setData(intrastats)
      const current = new Date()
      let currentIntrastats =  intrastats.filter((e) => Number(new Date(e.period).getMonth()) === Number(current.getMonth()+1) && e.state !== 'cancelled')
      currentIntrastats && setCurrentMonth(currentIntrastats)
    }
  }, [intrastats]);

  const getCurrentMonth = (lower?) => {
    const date = new Date()
    const current = new Date(`${date.getMonth()+1}/1/${date.getFullYear()}`)
    const resetM = date.getMonth()
    // current.setMonth(resetM)
    current.setMonth(resetM)
    const month = current.toLocaleDateString(lang, {
      month: 'long',
    })
    current.setMonth(resetM+1)
    const nexMonth = current.toLocaleDateString(lang, {
      month: 'long',
    })
    return (lower && month.toLocaleLowerCase()) || `${month} ⇒ ${nexMonth}`
  }


  return (
    <Wrapper display='flex' justifyContent='center'>
    {intrastatModal && iso && (
    <AddIntrastatModal 
      open={intrastatModal}
      handleClose={() => setIntrastatModal(false)}
      companyId={companyId}
      iso={iso}
    />
    )}
    {settings && (
    <IntrastatSettingsModal 
      open={settings}
      handleClose={() => setSettingsModal(false)}
      companyId={companyId}
      iso={iso}
    />
    )}
    {((data === null || intrastatsLoading || createIntrastatLoading) && <Loader />) || (
      <Wrapper height='100%' width='80%' overflow='auto'>
        <Divider hidden />
        <Wrapper display='flex'>
        <LinkedButton 
          labelPosition='left'
          icon='angle left'
          size='medium'
          type='button'
          content='Back'
          path={`${pathToIntrastat.intrastatCountryList.replace(':iso', iso)}`}
        />
        </Wrapper>
        <Divider hidden />
        <Header size='huge' textAlign='center'>EMEBI - INTRASTAT {intl.formatMessage({id: `intrastat.monit.Company`})}</Header>
        <Wrapper display='flex' justifyContent='flex-end !important'>
          <Button
            labelPosition='right'
            position='left'
            icon='setting'
            type='button'
            content={intl.formatMessage({id: `intrastat.monit.cronSettings`})}
            onClick={() => setSettingsModal(true)}
          />
          <Button
            labelPosition='right'
            position='left'
            icon='plus circle'
            type='button'
            content={intl.formatMessage({id: `intrastat.newShort`})}
            onClick={() => setIntrastatModal(true)}
          />
        </Wrapper>
        <Wrapper height='100%' overflow='auto'>
        <Tab panes={[
            {
              menuItem: (
                <Menu.Item key={0}>
                  INTRASTAT
                  <Label>{data && data.length}</Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Grid>
                    <Grid.Column>           
                      <IntrastatMainList
                        intl={intl}
                        key={0}
                        data={data}
                        companyId={match.params.companyId}
                        iso={match.params.iso}
                      />                                                               
                    </Grid.Column>                     
                  </Grid>
                </Tab.Pane>
              ),
            },
            {
              menuItem: (
                <Menu.Item key={1}>
                  {getCurrentMonth()}
                  <Label>{currentData && currentData.length}</Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Grid>
                    <Grid.Column>
                      <Header textAlign='center' size='small'>
                        INTRAT-STATS for {getCurrentMonth(true)} 
                      </Header>                   
                      <IntrastatMainList
                        intl={intl}
                        key={0}
                        data={currentData}
                        companyId={match.params.companyId}
                        iso={match.params.iso}
                      />                                                                 
                    </Grid.Column>                     
                  </Grid>
                </Tab.Pane>
              ),
            },   
        ]}/>

      <Wrapper>
      <Divider hidden />
      </Wrapper>
      </Wrapper>
  </Wrapper>
  )}
  </Wrapper> 
  )
}
const mapStateToProps = (state) => {
  return {
    intrastats: selectors.intrastat.allIntrastatsSelector(state),
    intrastatsLoading: selectors.intrastat.getAllIntrastatsLoadingSelector(state),
    createIntrastatLoading: selectors.intrastat.createOneIntrastatLoadingSelector(state)
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
