import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Header, Segment } from 'semantic-ui-react';
import EmptyTable from 'components/EmptyTable';
import { Period, Spacer, State, Title, width250, width150, width170, titleExpe, titleIntro } from './styles';

const Index = ({
  intl,
  data,
  iso,
  monitoringPathToDetail
}: IntrastatMainListProps) => {
  const intro = data.filter((e) => e.flow === 'introduction')
  const expe = data.filter((e) => e.flow === 'expedition')
  const none = data.filter((e) => e.flow === 'none')

  const buildLink = (companyId: number, debId: string, iso?: string) => {
    return iso ? `/intrastat/country/:iso/:companyId/:intrastatId`.replace(':iso', iso).replace(':companyId', `${companyId}`).replace(':intrastatId', debId) : 
    monitoringPathToDetail ? `/intrastat/:companyId/:intrastatId`.replace(':companyId', `${companyId}`).replace(':intrastatId', debId) :
    `/intrastat-dashboard/:companyId/:intrastatId`.replace(':companyId', `${companyId}`).replace(':intrastatId', debId)
  }

  const trans = (el) => intl.formatMessage({id: `intrastat.prog.${el}`})
  const statement = (category) => {
    return category.step === 5 ? 'red' : category.step === 1 ? 
    '#00619b' :  category.step === 4 ? 
    '#009900' : category.step === 3 ? 
    '#dd600b' : '#dda80b';
  }

  const lang = localStorage.getItem('language')

  return (
    <>
      {data === null && (
        <Segment>
          <Header
            textAlign='center'
            size='small'
          >{`No intrastat have been uploaded.`}</Header>
        </Segment>
      )}

      {data !== null && (
        <Table sortable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {intl.formatMessage({
                  id: `intrastat.table.FilingPeriod`
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>
              {intl.formatMessage({
                  id: `intrastat.table.Country`
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>
              {intl.formatMessage({
                  id: `intrastat.table.CreatedAt`
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>
              {intl.formatMessage({
                  id: `intrastat.table.Customer`
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>
              {intl.formatMessage({
                  id: `intrastat.table.Type`
                })}
              </Table.HeaderCell>             
              <Table.HeaderCell>
              {intl.formatMessage({
                  id: `intrastat.table.Flow`
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>
              {intl.formatMessage({
                  id: `intrastat.table.Emitter`
                })}
              </Table.HeaderCell> 
              <Table.HeaderCell>
              {intl.formatMessage({
                  id: `intrastat.table.Followers`
                })}
              </Table.HeaderCell>                         
              <Table.HeaderCell>
              {intl.formatMessage({
                  id: `intrastat.table.State`
                })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
          {(data && data.length === 0 && <EmptyTable colNumber={9} />) || (
            <>
            {expe.length>0 && (
              <Table.Row verticalAlign='middle'>
                <Title colSpan={9} style={titleExpe}> ⇐ {intl.formatMessage({id: `intrastat.expedition`})}</Title>
              </Table.Row>
            )}    
            {expe.map((e, i) => {
              return (
                <Table.Row key={`expe-${i}`} verticalAlign='middle'>
                  <Link
                    to={{pathname: buildLink(e.Company_Id, `${e.id}`, iso), ...monitoringPathToDetail && {state: {locPath: 'monitoring'}}}} className='table-link'
                  >
                  <Table.Cell key={`period-${i}`} style={width170}>
                    <Period>{new Date(e.period).toLocaleDateString(lang, {month: 'long'})}
                      <Spacer>{new Date(e.period).toLocaleDateString(lang, {year: 'numeric'})}</Spacer>
                    </Period>                                     
                  </Table.Cell> 
                  <Table.Cell key={`country-${i}`}> 
                      {e.filingCountry}
                  </Table.Cell>                 
                  <Table.Cell key='createdAt' style={width150}> 
                  {new Date(e.createdAt).toLocaleDateString('en', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })}</Table.Cell>

                  <Table.Cell key={`customer-${i}`} style={width250}>{e.Company && e.Company.name}</Table.Cell>
                  <Table.Cell key={`type-${i}`} style={width150}>
                    {intl.formatMessage({
                      id: `intrastat.${e.declarationType.toLowerCase()}`
                    })}</Table.Cell>
                  <Table.Cell key={`flow-${i}`} style={width150}> 
                    {intl.formatMessage({
                      id: `intrastat.${e.flow.toLowerCase()}`
                    })}</Table.Cell>
                  <Table.Cell key={`emitter-${i}`} style={width250}>{e.emitter}</Table.Cell>
                  <Table.Cell key={`follower-${i}`} style={width250}>{e.follower && (
                    <ul style={{ margin: '0'}}>
                      {e.follower.map((e, i) => (
                        <li key={`fol-${i}`}>{e.operator}</li>
                      ))}
                    </ul>
                  )}</Table.Cell>
                  <Table.Cell key={`state-${i}`}> <State defaultValue={
                     (!e.DeclarationHasCategory ||  e.DeclarationHasCategory.step === 5) ? 'red' : e.DeclarationHasCategory.step === 1 ? 
                      '#00619b' :  e.DeclarationHasCategory.step === 4 ? 
                      '#009900' : e.DeclarationHasCategory.step === 3 ? 
                      '#dd600b' : '#dda80b'}>{
                        (!e.DeclarationHasCategory || e.DeclarationHasCategory.step === 5) ? trans('cancelled')  : trans(e.DeclarationHasCategory.step-1) 
                        }</State>
                  </Table.Cell>                
                </Link>
                </Table.Row>          
              );
            })}
            {none.length>0 && (
              <Table.Row verticalAlign='middle'>
                <Title colSpan={9} style={{background: 'grey', color: 'black', padding: '6px 0 4px 10px', fontWeight: 'bold'}}>
                  {intl.formatMessage({
                    id: `intrastat.none`
                  })}
                  </Title>
              </Table.Row>
            )}
            {none.map((e, i) => {
              return (
                <Table.Row key={`none-${i}`} verticalAlign='middle'>
                   <Link to={{pathname: buildLink(e.Company_Id, `${e.id}`, iso), ...monitoringPathToDetail && {state: {locPath: 'monitoring'}}}} className='table-link'
                    >
                  <Table.Cell key={`period-${i}`} style={width170}>
                  <Period>{new Date(e.period).toLocaleDateString(lang, {
                      month: 'long',
                    })}
                    <Spacer>{new Date(e.period).toLocaleDateString(lang, {
                        year: 'numeric',
                      })}</Spacer>
                    </Period>                                     
                  </Table.Cell>
                  <Table.Cell key={`country-${i}`}> 
                      {e.filingCountry}
                  </Table.Cell>                                
                  <Table.Cell key={`createdAt-${i}`} style={width150}> 
                  {new Date(e.createdAt).toLocaleDateString(lang, {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })}</Table.Cell>   
                  <Table.Cell key={`customer-${i}`} style={width250}>{e.Company && e.Company.name}</Table.Cell>
                  <Table.Cell key={`type-${i}`} style={width150}>
                    {e.declarationType === 'none' && intl.formatMessage({
                      id: `intrastat.none`
                    })}
                  </Table.Cell>
                  <Table.Cell key={`flow-${i}`} style={width150}>{e.flow === 'none' && ''}</Table.Cell>
                  <Table.Cell key={`emitter-${i}`} style={width250}>{e.emitter}</Table.Cell>
                  <Table.Cell key={`follower-${i}`} style={width250}>{e.follower && (
                    <ul style={{ margin: '0'}}>
                      {e.follower.map((e, i) => (
                        <li key={i}>{e.operator}</li>
                      ))}
                    </ul>
                  )}</Table.Cell>
                  <Table.Cell key={`state-${i}`} style={width250}>
                  <State defaultValue={
                      (!e.DeclarationHasCategory || e.DeclarationHasCategory.step === 5) ? 'red' : e.DeclarationHasCategory.step === 1 ? 
                        '#00619b' :  e.DeclarationHasCategory.step === 4 ? 
                        '#009900' : e.DeclarationHasCategory.step === 3 ? 
                        '#dd600b' : '#dda80b'}>{
                          (!e.DeclarationHasCategory || e.DeclarationHasCategory.step === 5) ? trans('cancelled') : trans(e.DeclarationHasCategory.step-1) 
                          }</State>
                    </Table.Cell>                 
                  </Link>
                </Table.Row>        
              )})}

            {intro.length>0 && (
              <Table.Row verticalAlign='middle'>
                <Title colSpan={9} style={titleIntro}>{intl.formatMessage({
                  id: `intrastat.introduction`
                })} ⇒</Title>
              </Table.Row>
            )}          
            {intro.map((e, i) => {
              return (
                <Table.Row key={`intro-${i}`} verticalAlign='middle'>
                   <Link to={{pathname: buildLink(e.Company_Id, `${e.id}`, iso), ...monitoringPathToDetail && {state: {locPath: 'monitoring'}}}} className='table-link'>
                  
                  <Table.Cell key={`period-${i}`} style={width170}>
                    <Period>{new Date(e.period).toLocaleDateString(lang, {month: 'long'})}
                      <Spacer>{new Date(e.period).toLocaleDateString(lang, {year: 'numeric'})}</Spacer>
                     </Period>                                     
                  </Table.Cell> 
                  
                  <Table.Cell key={`country-${i}`}> 
                      {e.filingCountry}
                  </Table.Cell>                                   
                  
                  <Table.Cell key={i+3} style={width150}> 
                    {new Date(e.createdAt).toLocaleDateString(lang, {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })}
                  </Table.Cell>
                  
                  <Table.Cell key={`customer-${i}`} style={width250}>{e.Company && e.Company.name}</Table.Cell>
                  
                  <Table.Cell key={`type-${i}`} style={width150}>
                    {intl.formatMessage({
                      id: `intrastat.${e.declarationType.toLowerCase()}`
                    })}
                  </Table.Cell>
                  
                  <Table.Cell key={`flow-${i}`} style={width150}>
                    {intl.formatMessage({
                      id: `intrastat.${e.flow.toLowerCase()}`
                    })}
                  </Table.Cell>
                  
                  <Table.Cell key={`emitter-${i}`} style={width250}>{e.emitter}</Table.Cell>
                  
                  <Table.Cell key={`follower-${i}`} style={width250}>{e.follower && (
                    <ul style={{ margin: '0'}}>
                      {e.follower.map((e, i) => (
                        <li key={`fol-${i}`}>{e.operator}</li>
                      ))}
                    </ul>
                  )}</Table.Cell>
                  <Table.Cell key={`state-${i}`} style={width250}>
                    <State defaultValue={
                      (!e.DeclarationHasCategory || e.DeclarationHasCategory.step === 5) ? 'red' : e.DeclarationHasCategory.step === 1 ? 
                        '#00619b' :  e.DeclarationHasCategory.step === 4 ? 
                        '#009900' : e.DeclarationHasCategory.step === 3 ? 
                        '#dd600b' : '#dda80b'}>{
                          (!e.DeclarationHasCategory || e.DeclarationHasCategory.step === 5) ? trans('cancelled') : trans(e.DeclarationHasCategory.step-1) 
                          }</State>
                  </Table.Cell>  
                  </Link>
                </Table.Row>        
              )})}
          </>
          )}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

interface IntrastatMainListProps {
  intl: any;
  data: Array<{
    id: number;
    Company: any;
    Company_Id: number;
    flow: string;
    emitter: string;
    follower: any;
    declarationType: string;
    DeclarationHasCategory: any;
    period: string;
    filingCountry: string;
    state: string;
    createdAt: string;
  }>;
  companyId?: number;
  iso?: string;
  monitoringPathToDetail?: boolean;
}

export default Index;
