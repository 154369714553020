import button from "./button";
import input from "./input";
import services from "./services";
import countries from "./countries";
import intrastats from "./intrastats";
import chat from './chat';

export default {
  "app.title": "My ASD",
  ...input,
  ...button,
  ...services,
  ...countries,
  ...intrastats,
  ...chat
};
