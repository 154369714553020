import { orderBy } from 'lodash';
import { Field, FieldArray, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Form,
  Header,
  Modal,
  Divider,
  Transition,
  Message,
} from 'semantic-ui-react';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { settingsSchema } from '../../../../interfaces/formSchema/intrastatValidators';
import Wrapper from 'components/core/Wrapper/Wrapper';


const Index = ({
  dispatch,
  companyId,
  open,
  settingsData,
  settingsLoading,
  handleClose,
  euCountries,
  intl,
  loadingHandlerData,
}: settingsProps) => {

  const [allCountriesList, setAllCountriesList]: any = useState([]);
  const [data, setData]: any = useState(null)

  useEffect(() => {
    dispatch(actions.data.getEuCountries());
    dispatch(actions.intrastat.getSettings({
      params: {
        companyId: companyId
      }
    }));
    return () => {
      dispatch(actions.data.reset(['euCountries', 'error']));
      dispatch(actions.intrastat.reset(['getSettings', 'error']));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    if(settingsData){
      setData(settingsData.map((e) => {
        return {
          Country: e.Country,
          declarationType: e.declarationType,
          flow: e.flow,
        }
      }))
    }
  }, [settingsData])


  useEffect(() => {
    if(euCountries){
      setAllCountriesList(
        orderBy(
          euCountries.map((country, index) => {
            return {
              key: index,
              value: country.country_code,
              text: intl.formatMessage({
                id: `countries.name.${country.country_code}`,
              }),
              flag: country.country_code === 'XI' ? 'gb' : country.country_code.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [euCountries, intl]);

 
  const save = (values) => {
    dispatch(
      actions.intrastat.settings({
        params: {
          companyId: companyId,
        },
        data: values,
      })
    )
    handleClose()
  }
   

  
  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='setting' content={intl.formatMessage({id: `intrastat.monit.setting`})} />
      <Formik
        initialValues={{
          settings: data || [{}], 
        }}
        enableReinitialize={true}
        onSubmit={save}
        validationSchema={settingsSchema}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
        }: any) => (
       
          <Modal.Content loading={settingsLoading}>         
          <Form onSubmit={handleSubmit}>
          <Wrapper display='flex' flexDirection='column' alignItems='center'>
            <Divider hidden />
            <FieldArray
              name='settings'
              render={(arrayHelpers) => (
                <>
                <Transition.Group
                  duration={200}
                >  

                  {settingsData && settingsData.length === 0 && (                            
                    <Message info>Assign some countries and obligations to this company account</Message>                            
                  )}

                  <Divider hidden /> 

                  {values.settings.map((e: any, index) => {
                    return (                   
                    <Wrapper key={index} display='flex' width='80%' height='55px' alignItems='center' justifyContent='space-between' flexWrap='nowrap'> 
                      <Wrapper key={`${index}-a`} width='30%'>                         
                        <Field
                          key={`${index}-ab`}
                          placeholder='select country...'
                          name={`settings[${index}].Country`}
                          component={Dropdown}
                          options={allCountriesList}
                          loading={loadingHandlerData}             
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        />
                      </Wrapper>
                      <Wrapper key={`${index}-ab`} width='30%'> 
                        <Field
                          key={`${index}-aba`}
                          placeholder='select type...'
                          name={`settings[${index}].declarationType`}
                          component={Dropdown}
                          options={
                            [
                              {
                                  key: 0,
                                  value: 'deb',
                                  text: `${intl.formatMessage({id: `intrastat.deb`})}`
                              },
                              {
                                  key: 1,
                                  value: 'des',
                                  text: `${intl.formatMessage({id: `intrastat.des`})}`
                              },
                            ]
                          }
                          loading={loadingHandlerData}
                          meta={{
                              errors,
                              touched,
                              setFieldValue,
                              setFieldTouched,
                          }}
                        /> 
                      </Wrapper>
                      <Wrapper key={`${index}-ac`} width='30%'>                        
                        <Field
                          key={`${index}-aca`}
                          placeholder='select flow...'
                          name={`settings[${index}].flow`}
                          component={Dropdown}
                          options={[
                            {
                                key: 0,
                                value: 'introduction',
                                text: `${intl.formatMessage({id: `intrastat.introduction`})}`
                            },
                            {
                                key: 1,
                                value: 'expedition',
                                text: `${intl.formatMessage({id: `intrastat.expedition`})}`
                            },
                          ]}           
                          meta={{
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                          }}
                        />
                      </Wrapper>                   
                      <Button
                        icon='minus'
                        type='button'
                        color='red'
                        onClick={() => {
                          arrayHelpers.remove(index)
                        }}
                        maxHeight='2.7rem'
                      />                   
                    </Wrapper>
                    )})
                  }
              </Transition.Group>
              <Divider hidden/>
              <Button
                icon='plus'
                type='button'
                onClick={() =>                       
                  arrayHelpers.push({
                    country: undefined,
                    periodicity: undefined,
                  })                           
                }
                margin='0px'
                position='center'
              /> 
              </>
            )}/> 
            </Wrapper>           
            {/* <ErrorMessage
              name='permissions'
              render={(msg) => <Error msg={msg} />}
            /> */}
     
              <Divider hidden/>   
              <Button type='submit' content={intl.formatMessage({id: `button.submit`})} />
        
          </Form>
     
      
      </Modal.Content>  
      )}
      </Formik>
    </Modal>
  );
};

interface settingsProps {
  dispatch: any
  companyId: number;
  open: boolean;
  settingsData: any;
  handleClose: () => void;
  euCountries: any;
  intl;
  settingsLoading,
  loadingHandlerData;
  categoriesDocuments;
  categoriesLoading;
}


const mapStateToProps = (state) => {
  return {
    euCountries: selectors.data.euCountriesSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    loadingHandlerData: selectors.data.euCountriesLoadingSelector(state),
    settingsLoading: selectors.intrastat.loadingSettingsSelector(state),
    settingsData: selectors.intrastat.settingsGetSelector(state),
  };
  
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
