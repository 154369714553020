import { orderBy } from 'lodash';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Divider,
  Form,
  Grid,
  Header,
  Modal,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import { updateIntrastat, updateNoneIntrastat } from 'interfaces/formSchema/intrastatValidators';
import Dropdown from 'components/Dropdown';
import Input from 'components/Form/Input';

const ModalEditIntrastat = ({
  open,
  actualData,
  handleEditIntrastat,
  handleClose,
  intl,
  dispatch,
  euCountries,
  euCountriesLoading,
  intCategories,
  intCategoriesLoading,
  companyId,
}: ModalEditIntrastatProps) => {

  const [allCountriesList, setAllCountriesList]: any = useState([]);
  const [intCategoriesList, setintCategoriesList]: any = useState(null);
  const [Categories, setCategories]: any = useState(null);
  const [isNone, setIsNone]: any = useState(null)

  useEffect(() => {
    dispatch(actions.intrastat.getCategories({
      params: {
        companyId: companyId
      }
    }));
    dispatch(actions.companyIntrastatSettings.getAssignedCountries({
      params: {
        companyId: companyId
      }
    }));
    return () => {
      dispatch(actions.companyIntrastatSettings.reset(['countries', 'error']));
      dispatch(actions.intrastat.reset(['categories', 'error']));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    if(intCategories){
      setintCategoriesList(intCategories.map((e, i) => {
        return {
          key: i,
          value: {id: e.Category_Id, name: e.name},
          text: intl.formatMessage({
            id: `intrastat.${e.name.toLowerCase()}`,
          }),
        }
      }))
    }
  }, [intCategories, intl, actualData.declarationType])

  useEffect(() => {
    if(euCountries){
      setAllCountriesList(
        orderBy(
          euCountries.map((country, index) => {
            return {
              key: index,
              value: country.iso,
              text: intl.formatMessage({
                id: `countries.name.${country.iso}`,
              }),
              flag: country.iso.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [euCountries, intl]);

  useEffect(() => {
    if(actualData && intCategories){
      const category = intCategories.find((e) => e.name === actualData.declarationType.toUpperCase()) 
      category && setCategories({id: category.Category_Id, name: category.name})
      setIsNone(actualData.declarationType === 'none' ? {id: 3, name: 'none'} : null)
    }
  }, [actualData, intCategories, intCategoriesList])

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='edit' content={intl.formatMessage({
        id: `intrastat.edit` 
        }, 
        {declarationType: intl.formatMessage({
          id: `intrastat.${actualData.declarationType.toLowerCase()}`,
        })})} />
      <Formik
        initialValues={{
          period: actualData.period.split('T')[0] || '',
          filingCountry: actualData.filingCountry || '',
          declarationType: Categories || isNone || '', // send TEXT 
          flow: actualData.flow || '',
          clientReference: actualData.clientReference || '',
        }}
        enableReinitialize={true}
        onSubmit={handleEditIntrastat}
        validationSchema={isNone ? updateNoneIntrastat : updateIntrastat}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
        }) => (
        <Modal.Content>
        <Form onSubmit={handleSubmit}>
        <Grid>
        {actualData.declarationType !== 'none' && (
          <Grid.Row columns='2'>
          <Grid.Column> 
            <Field
              mandatory
              label={intl.formatMessage({
                id: `intrastat.declarationType` 
              })}
              name='declarationType'
              placeholder={intl.formatMessage({
                id: `intrastat.cDType` 
              })}
              component={Dropdown}
              options={intCategoriesList}
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
            />
          </Grid.Column>
            <Grid.Column>
              <Field
                mandatory
                label={intl.formatMessage({id: `intrastat.flow` })}
                placeholder={intl.formatMessage({id: `intrastat.cFlow` })}
                name='flow'
                component={Dropdown}
                options={[
                  {
                      key: 0,
                      value: 'expedition',
                      text: intl.formatMessage({
                        id: `intrastat.expedition`,
                      }),
                  },
                  {
                      key: 1,
                      value: 'introduction',
                      text: intl.formatMessage({
                        id: `intrastat.introduction`,
                      }),
                  },
                ]}
                meta={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}
              />
            </Grid.Column>
            </Grid.Row>
        )}
        <Grid.Row columns={2}>
        <Grid.Column>
          <Field
            mandatory
            label={intl.formatMessage({
              id: `intrastat.reportingPeriod` 
            })}
            name='period'
            component={Input}
            type='date'
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
        </Grid.Column>     
        <Grid.Column>
          <Field
            mandatory
            label={intl.formatMessage({
              id: `intrastat.filingCountry` 
            })}
            name='filingCountry'
            placeholder={intl.formatMessage({
              id: `intrastat.choose` 
            })}
            component={Dropdown}
            options={allCountriesList}
            loading={euCountriesLoading}
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
        </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
          <Field
            label={intl.formatMessage({
              id: `intrastat.clientReference` 
            })}
            name='clientReference'
            placeholder='REF5765...'
            component={Input}
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
          </Grid.Column>
        </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Button
          disabled={isSubmitting}
          type='submit'
          content={intl.formatMessage({
            id: `intrastat.submit` 
          })}
          color='green'
        />
        </Form>
      </Modal.Content>
      )}
    </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    intCategories: selectors.intrastat.getCategoriesSelector(state),
    intCategoriesLoading: selectors.intrastat.getCategoriesLoadingSelector(state),
    euCountries: selectors.companyIntrastatSettings.getCountriesSelector(state),
    euCountriesLoading: selectors.companyIntrastatSettings.getCountriesLoadingSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

type declarationData = {
  period: string;
  filingCountry: string;
  declarationType: string;
  flow: string;
  clientReference: string;
};

interface ModalEditIntrastatProps {
  open: boolean;
  actualData: any;
  handleEditIntrastat: (values: declarationData) => void;
  handleClose: () => void;
  dispatch: any;
  intl;
  intCategories;
  intCategoriesLoading;
  euCountries;
  euCountriesLoading;
  companyId;
}

export default enhance(ModalEditIntrastat);
