import {orderBy, filter, sortBy} from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Header, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import { Link } from 'react-router-dom';
import Search from 'components/Search';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths as pathToIntrastat } from 'routes/intrastat';
import LinkedButton from 'components/core/LinkedButton/LinkedButton';

const Index = ({ 
  intl,
  dispatch,
  history,
  match, 
  user, 
  companies,
  companiesLoading,
  userCompanies,
 }) => {
  document.title = `MyASD Intrastat monitoring`;
  const userAdminOrOp = user && (user.operator || user.admin);
  const iso = match.params.iso.toUpperCase()
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);
  const [resetSearch, setresetSearch] = useState(false);

  useEffect(() => {
    if(userAdminOrOp){
      dispatch(actions.intrastat.getCompanySubscribed());
      return () => {
        dispatch(
          actions.intrastat.reset(['companySubscribed', 'error'])
        );
      };
    }else{
      dispatch(
        actions.companyUser.getUserCompanies({
          params: { userId: window.location.pathname.split('/')[3] },
        })
      );
    }
  }, [dispatch, userAdminOrOp]);


  useEffect(() => {
    setresetSearch(false);
    if(companies || userCompanies){
      setdata(orderBy(filter(companies || userCompanies, (o) => o.country === iso), ['name'], ['asc']));
    }
  }, [companies, userCompanies, iso]);


  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const onSearchResultChange = (newData) => {
    setdata(orderBy(newData, ['name'], ['asc']));
  };

  return (
    <Wrapper overflow='auto' width='80%' margin='auto'>
      <Divider hidden />
        <Wrapper display='flex'>
          <LinkedButton 
            labelPosition='left'
            icon='angle left'
            size='medium'
            type='button'
            content='Back'
            path={`${pathToIntrastat.intrastat}`}
          />
        </Wrapper>
      <Divider hidden />
      <Wrapper padding='2rem' textAlign='center'>
        <Header size='huge'>
          EMEBI - INTRASTAT
          <Header.Subheader>(operators - companies)</Header.Subheader>
        </Header>
        <Wrapper>
          <Divider hidden />
        </Wrapper>
      </Wrapper>
      {((
        data === null || 
        companiesLoading || 
        companies === null
        ) && <Loader />
      ) || (
      userAdminOrOp && (
      <Wrapper>
        <Wrapper display='flex' justifyContent='space-between'>
          <Search
            dataSource={companies}
            onSearchResultChange={onSearchResultChange}
            setResetSearch={setresetSearch}
          />
        </Wrapper>
          <Table size='large' columns='3' sortable selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'name' ? direction : null}
                  onClick={() => handleSort('name')}
                >
                {intl.formatMessage({id: `intrastat.monit.Name`})}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'email' ? direction : null}
                  onClick={() => handleSort('email')}
                >
                {intl.formatMessage({id: `intrastat.monit.Email`})}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country' ? direction : null}
                  onClick={() => handleSort('country')}
                >
                {intl.formatMessage({id: `intrastat.monit.Country`})}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {(data.length === 0 && <EmptyTable colNumber={3} />) ||
                data.map((company, i) => {
                  return (
                    <Table.Row key={i}>
                      <Link
                        to={`/intrastat/country/:iso/:companyId`
                        .replace(':iso', 'fr')
                        .replace(':companyId', company.id)}
                        className='table-link'
                      >
                        <Table.Cell>{company.name}</Table.Cell>
                        <Table.Cell>{company.email}</Table.Cell>
                        <Table.Cell>{company.country}</Table.Cell>
                      </Link>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>
      ))}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.intrastat.companySubscribedSelector(state),
    companiesLoading: selectors.intrastat.companySubscribedLoadingSelector(state),
    userCompanies: selectors.companyUser.userCompaniesSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);

